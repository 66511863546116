<template>
  <div class="conectividadPublica">
        <!-- CONTENIDO -->
    <div class="container">
         <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
     <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Programa de Conectividad en Sitios Públicos 2023</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Programa de Conectividad en Sitios Públicos 2023</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <p class="text-justify">La cobertura universal de servicios de telecomunicaciones es un derecho humano, que el Estado Mexicano debe garantizar a través de la definición de políticas, programas y líneas de acción orientadas hacia ese fin.</p>
		      <p class="text-justify">En este sentido, la Secretaría de Infraestructura, Comunicaciones y Transportes (SICT) de acuerdo con lo establecido en el Título Décimo, Capítulo Único, Artículos 210 y 211 de la Ley Federal de Telecomunicaciones y Radiodifusión (LFTR) es la encargada de emitir anualmente los Programas de Cobertura Social y de Conectividad en Sitios Públicos.</p>
		      <p class="text-justify">El Programa de Conectividad en Sitios Públicos 2023  <strong>(PCSP 2023)</strong> tiene como objetivo la identificación y localización de sitios públicos en todo el territorio nacional con necesidades de conectividad gratuita a Internet a fin de concretar la cobertura universal.</p>
		  	  <p class="text-justify">La estrategia de conectividad definida por el <strong>PCSP 2023</strong> se sustenta en lo dispuesto en el artículo 6° de la Constitución Política de los Estados Unidos Mexicanos, así como en los conceptos de servicios públicos de interés general, cobertura universal y acceso a los derechos sociales, retomados en la Ley General de Desarrollo Social y en la LFTR.</p>
          <p class="text-justify">Por esta razón, el <strong>PCSP 2023</strong> subraya la importancia de que ese servicio público de interés general sea proveído bajo esquemas de conectividad significativa y asequibilidad.</p>
		      <p class="text-justify">Como parte integral del <strong>PCSP 2023</strong>, la SICT diseñó el Repositorio Único de Sitios Públicos Conectados y por Conectar (RUSP) que consiste en un sistema informático que facilitará el registro, validación, actualización y consulta de la información de los sitios públicos que requieran conectividad a Internet con la finalidad de que sea considerada por operadores de telecomunicaciones y por la empresa productiva del Estado "CFE Telecomunicaciones e Internet para todos", en el despliegue de la oferta de sus servicios.</p>
		   
		  <hr>
		  
		  <p class="text-justify"></p>
	      <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Programa_de_Conectividad_en_Sitios_Publicos_2023.pdf" download="Conectividad_2023.pdf">Descargar archivo PDF</a>
		    <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Anexo_1_Base_de_Datos_de_sitios_p_blicos_por_conectar.xlsx" download="Anexo_1_Base_de_Datos_de_sitios_p_blicos_por_conectar.xlsx">Anexo 1 Programa de Conectividad Sitios Públicos</a>
        <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Anexo_2_Base_de_Datos_de_sitios_p_blicos_prioritarios_por_conectar.xlsx" download="Anexo_2_Base_de_Datos_de_sitios_p_blicos_prioritarios_por_conectar.xlsx">Anexo 2 Programa de Conectividad Sitios Públicos-Prioritarios</a>
        </div>
      </div>
     
       <div class="row"><s></s>
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href="/conectividad-sitios-publicosInter">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'conectividadPublica',
  data(){
    return{
      pdfData:''
    }
  },
 mounted(){
   
 },
  methods: {
    

  },
}
</script>

